import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export const UhcSansName = `'UHC2020 Sans'`;
export const UhcSerifName = `'UHC Serif'`;

export const fontFamilyAbyssSans = `AbyssUHCSans, ${UhcSansName}, Helvetica, Arial, sans-serif`;
export const fontFamilySans = `${UhcSansName}, Helvetica, Arial, sans-serif`;
export const fontFamilySerif = `${UhcSerifName}, Georgia, serif`;
export const fontFamilyHeadline = 'UHC Serif Headline';
export const fontWeightLight = 400;
export const fontWeightNormal = 500;
export const fontWeightSemibold = 600;
export const fontWeightBold = 700;

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type TextProps = PropsWithChildren<{
  bold?: boolean;
  semiBold?: boolean;
  smallText?: boolean;
  spacingBottom?: boolean | number;
  tabIndex?: number;
  flex?: number;
}>;

export const textProps = css<TextProps>`
  margin: 0;
  ${({ bold }) =>
    bold === true ? `font-weight: ${fontWeightBold}` : undefined};
  ${({ semiBold }) =>
    semiBold === true ? `font-weight: ${fontWeightSemibold}` : undefined};
  ${({ smallText }) => (smallText === true ? smallTextStyles : undefined)};
  ${({ spacingBottom }) =>
    /* Add 1rem bottom margin by default or use custom amount in rems. */
    typeof spacingBottom === 'number'
      ? `margin-bottom: ${spacingBottom}rem`
      : spacingBottom === true
      ? 'margin-bottom: 1rem'
      : undefined};
  ${({ flex }) => flex && 'flex: 1'};
`;

export const smallTextStyles = css`
  font-size: 0.79rem;
  line-height: 1.2658;
`;

const h1Styles = css`
  color: #002677;
  font-family: ${fontFamilySerif};
  font-weight: ${fontWeightSemibold};
  font-size: 1.802rem;
  line-height: 1.25;
  font-synthesis: style small-caps;
`;

export const H1 = styled.h1<TextProps>`
  ${h1Styles}
  ${textProps}
`;

const h2Styles = css`
  color: #002677;
  font-family: ${fontFamilySerif};
  font-size: 1.602rem;
  font-weight: ${fontWeightSemibold};
  line-height: 1.3;
  font-synthesis: style small-caps;
`;

export const H2 = styled.h2<TextProps>`
  ${h2Styles}
  ${textProps}
`;

export const h3Styles = css`
  color: #002677;
  font-family: ${fontFamilySerif};
  font-size: 1.42375rem;
  font-weight: ${fontWeightSemibold};
  line-height: 1.23;
  font-synthesis: style small-caps;
`;

export const H3 = styled.h3<TextProps>`
  ${h3Styles}
  ${textProps}
`;

export const h4Styles = css`
  color: #002677;
  font-family: ${fontFamilySans};
  font-size: 1.125rem;
  font-weight: ${fontWeightBold};
  line-height: 1.3;
  font-synthesis: style small-caps;
`;

export const H4 = styled.h4<TextProps>`
  ${h4Styles}
  ${textProps}
`;

export const h5Styles = css`
  color: #002677;
  font-family: ${fontFamilySans};
  font-size: 1.125rem;
  font-weight: ${fontWeightBold};
  line-height: 1.4047;
  font-synthesis: style small-caps;
`;

export const H5 = styled.h5<TextProps>`
  ${h5Styles}
  ${textProps}
`;

export const h6Styles = css`
  color: #002677;
  font-family: ${fontFamilySans};
  font-size: 1rem;
  font-weight: ${fontWeightBold};
  line-height: 1.25;
  font-synthesis: style small-caps;
`;

export const H6 = styled.h6<TextProps>`
  ${h6Styles}
  ${textProps}
`;

export const plainTextStyles = css`
  color: #5a5a5a;
  font-family: ${fontFamilySans};
  font-size: 1rem;
  font-weight: ${fontWeightNormal};
  line-height: 1.25;
`;

export const P = styled.p<TextProps>`
  ${plainTextStyles}
  ${textProps}
`;

export const BodyCopy1 = styled(P)`
  font-size: 1.125rem;
  line-height: 1.3333;
`;

export const BodyCopy2 = P;

export const BodyCopy3 = styled(P)`
  font-size: 0.889rem;
  line-height: 1.1252;
`;

export const BodyCopy4 = styled(P)`
  ${smallTextStyles}
`;

export const Span = styled.span<TextProps>`
  ${plainTextStyles}
  ${textProps}
`;

export const SmallLabel = styled.label<TextProps>`
  ${plainTextStyles}
  ${textProps}
  ${smallTextStyles}
`;

/** ol/ul */
const listMarkerStyle = css`
  color: #002677;
  font-size: 1.125rem;
  font-weight: 700;
`;
const listStyles = css`
  ${plainTextStyles}
  padding-left: 1.5rem;
`;
export const Ol = styled.ol<TextProps>`
  ${listStyles}
  ${textProps}
`;
export const Ul = styled.ul<TextProps>`
  ${listStyles}
  ${textProps}
`;
/** A Styled `li` with the option of using a class 'blue-marker' to make the marker(bullets or numbers),
 * specifically styled(diff color and font).
 * UX designed that the ordered list have the numbers styled differently * */
export const Li = styled.li<TextProps>`
  ${listStyles}
  ${textProps}
  margin-bottom: 1rem;
  padding-left: 0.125rem;
  :not(a) {
    &.blue-marker {
      &&::marker {
        ${listMarkerStyle}
      }
    }
  }
`;

export const DollarValue = styled.span`
  font-size: 1.8018rem;
  font-family: ${fontFamilySerif};
  font-weight: ${fontWeightSemibold};
  color: #002677;
`;

export const ExtraHelpLevelText = styled.span`
  font-family: ${fontFamilySans};
  font-weight: ${fontWeightBold};
  color: #333333;
`;

export const OneMonthSupplyContent = styled.span`
  font-family: ${fontFamilySans};
  font-weight: ${fontWeightBold};
  color: #333333;
  display: block;
`;

export const PreHeader = styled.span`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.125rem;
`;

/** Applies typography styles to HTML. */
export const Typography = styled.div`
  ${plainTextStyles}
  h1 {
    ${h1Styles}
    margin-bottom: 1rem;
  }
  h2 {
    ${h2Styles}
    margin-bottom: 1rem;
  }
  h3 {
    ${h3Styles}
    margin-bottom: 1rem;
  }
  h4 {
    ${h4Styles}
    margin-bottom: 1rem;
  }
  h5 {
    ${h5Styles}
    margin-bottom: 1rem;
  }
  h6 {
    ${h6Styles}
    margin-bottom: 1rem;
  }
  p {
    ${plainTextStyles}
    margin-bottom: 1rem;
  }
  ul,
  ol {
    ${listStyles}
    margin-bottom: 1rem;
  }
  a:link {
    color: #196ecf;
  }
`;
export type SpacingProps = {
  // padding
  padding?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;

  // border
  border?: string;
  borderTop?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderRight?: string;

  // margin
  margin?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
};

export const spacingCss = css<SpacingProps>`
  padding: ${(p) => p.padding};
  padding-top: ${(p) => p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom};
  padding-left: ${(p) => p.paddingLeft};
  padding-right: ${(p) => p.paddingRight};

  border: ${(p) => p.border};
  border-top: ${(p) => p.borderTop};
  border-bottom: ${(p) => p.borderBottom};
  border-left: ${(p) => p.borderLeft};
  border-right: ${(p) => p.borderRight};

  margin: ${(p) => p.margin};
  margin-top: ${(p) => p.marginTop};
  margin-bottom: ${(p) => p.marginBottom};
  margin-left: ${(p) => p.marginLeft};
  margin-right: ${(p) => p.marginRight};
`;

export type ColProps = SpacingProps & {
  fullWidth?: boolean;
  fullHeight?: boolean;
  // flex
  flex?: number | string;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
};

export const Col = styled.div<ColProps>`
  ${spacingCss}
  display: flex;
  flex-direction: column;
  flex: ${(p) => p.flex};
  align-items: ${(p) => p.alignItems};
  justify-content: ${(p) => p.justifyContent};

  ${(props) => props.fullWidth && 'width: 100%;'}
  ${(props) => props.fullHeight && 'height: 100%;'}
`;
