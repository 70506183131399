import React, { useCallback, useMemo, useState } from 'react';
import NextLink from 'next/link';
import isHotkey from 'is-hotkey';
import styled from 'styled-components';

import { Editable, withReact, useSlate, Slate } from 'slate-react';
import {
  Editor,
  Transforms,
  createEditor,
  Node,
  Element as SlateElement,
  Range,
} from 'slate';
import { withHistory } from 'slate-history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isUrl from 'is-url';
import {
  faListOl,
  faListUl,
  faLink,
  faQuoteLeft,
  faBold,
  faItalic,
  faUnderline,
  faCode,
  faBug,
} from '@fortawesome/free-solid-svg-icons';
import { Col, H1, H2, H3, H4, H5, H6, P, Ol } from './Typography';

const buttonIconMap = {
  bold: faBold,
  italic: faItalic,
  underline: faUnderline,
  code: faCode,
  'heading-one': 'H1',
  'heading-two': 'H2',
  'heading-three': 'H3',
  'heading-four': 'H4',
  'heading-five': 'H5',
  'heading-six': 'H6',
  'block-quote': faQuoteLeft,
  'numbered-list': faListOl,
  'bulleted-list': faListUl,
};

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

type RichTextEditorProps = {
  startingValue?: Node[] | (() => Node[]) | undefined;
  onValueChanged: (value: Node[]) => void;
};

const RichTextEditor = ({
  startingValue,
  onValueChanged,
  ...rest
}: RichTextEditorProps) => {
  const initialValue = [{ type: 'paragraph', children: [{ text: '' }] }];

  const [value, setValue] = useState<Node[]>(startingValue || initialValue);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  // @ts-ignore
  const editor = useMemo(
    () => withHistory(withLinks(withReact(createEditor()))),
    []
  );
  const [showJson, setShowJson] = useState(false);
  return (
    <EditorContainer flex="1">
      <Slate
        editor={editor}
        value={value}
        onChange={(value) => {
          onValueChanged(value);
          setValue(value);
        }}
      >
        <Toolbar>
          <MarkButton format="bold" />
          <MarkButton format="italic" />
          <MarkButton format="underline" />
          <MarkButton format="code" />
          <BlockButton format="heading-one" />
          <BlockButton format="heading-two" />
          <BlockButton format="heading-three" />
          <BlockButton format="heading-four" />
          <BlockButton format="heading-five" />
          <BlockButton format="heading-six" />
          <BlockButton format="block-quote" />
          <BlockButton format="numbered-list" />
          <BlockButton format="bulleted-list" />
          <LinkButton />
          <ToolbarButton active={false} onClick={() => setShowJson(!showJson)}>
            <FontAwesomeIcon icon={faBug} />
          </ToolbarButton>
        </Toolbar>

        <Editable
          css={`
            padding: 12px;
          `}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          spellCheck
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
          {...rest}
        />
      </Slate>

      {showJson && <pre>{JSON.stringify(value, null, 2)}</pre>}
    </EditorContainer>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => {
      // @ts-ignore
      const nodeIncluded =
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type;
      return LIST_TYPES.includes(nodeIncluded as string);
    },
    split: true,
  });

  const newProperties: Partial<SlateElement> = {
    // @ts-ignore
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    // @ts-ignore
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <H1 {...attributes}>{children}</H1>;
    case 'heading-two':
      return <H2 {...attributes}>{children}</H2>;
    case 'heading-three':
      return <H3 {...attributes}>{children}</H3>;
    case 'heading-four':
      return <H4 {...attributes}>{children}</H4>;
    case 'heading-five':
      return <H5 {...attributes}>{children}</H5>;
    case 'heading-six':
      return <H6 {...attributes}>{children}</H6>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <Ol {...attributes}>{children}</Ol>;
    case 'link':
      return (
        <NextLink href={element.url} {...attributes}>
          {children}
        </NextLink>
      );
    default:
      return <P {...attributes}>{children}</P>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format }) => {
  const editor = useSlate();

  const isHeading = format.startsWith('heading-');

  return (
    <ToolbarButton
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {isHeading ? (
        <strong>{buttonIconMap[format]}</strong>
      ) : (
        <FontAwesomeIcon icon={buttonIconMap[format]} />
      )}
    </ToolbarButton>
  );
};

const MarkButton = ({ format }) => {
  const editor = useSlate();
  return (
    <ToolbarButton
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <FontAwesomeIcon icon={buttonIconMap[format]} />
    </ToolbarButton>
  );
};

const LinkButton = () => {
  const editor = useSlate();
  return (
    <ToolbarButton
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault();
        const url = window.prompt('Enter the URL of the link:');
        if (!url) return;
        insertLink(editor, url);
      }}
    >
      <FontAwesomeIcon icon={faLink} />
    </ToolbarButton>
  );
};

const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    // @ts-ignore
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return !!link;
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    // @ts-ignore
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const EditorContainer = styled(Col)`
  border: 1px solid #e9e9e9;
  border-radius: 3px;

  min-height: 200px;
`;

const Toolbar = styled.div`
  display: inline-block;
  position: relative;
  padding: 12px;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
`;

type ToolbarButtonProps = {
  active: boolean;
};

const ToolbarButton = styled.button.attrs({
  type: 'button',
})<ToolbarButtonProps>`
  background-color: transparent;
  margin-left: 6px;
  margin-right: 6px;
  padding: 6px;
  border: ${(props) => (props.active ? `1px solid black` : `1px solid #eee`)};
  cursor: pointer;

  &&:hover {
    background-color: #eee;
  }
`;

export default RichTextEditor;
