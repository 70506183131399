import React, { useState } from 'react';
import {
  CustomMessagesDataTable,
  PlanDocumentsDataTable,
} from '@src/components';
import { Tabs } from '@abyss/web/ui/Tabs';
import { Router } from '@abyss/web/ui/Router';
import { Button } from '@abyss/web/ui/Button';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { useCustomMessages, usePlanDocuments } from '@src/hooks';
import { PlanDocumentSegment, Segment } from '@src/types';

export const App = ({ accessToken }: { accessToken: string | null }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [
    {
      data: customMessagesData,
      fetching: customMessagesFetching,
      error: customMessagesError,
    },
  ] = useCustomMessages({
    pause: !accessToken,
  });

  const [
    {
      data: planDocumentsData,
      fetching: planDocumentsFetching,
      error: planDocumentsError,
    },
  ] = usePlanDocuments({
    pause: !accessToken,
  });

  const dedupePolicyNumbers = (policyNumbers: string[]): string[] => {
    return [...new Set(policyNumbers)];
  };

  const generatePolicyNumberCell = (
    value: PlanDocumentSegment[] | Segment[]
  ): JSX.Element => {
    const policyNumbers = dedupePolicyNumbers(
      value
        .map((segment) => segment.policyNumber)
        .filter((policyNumber) => !!policyNumber) as string[]
    );

    const isMultiplePolicies = policyNumbers.length > 1;

    return (
      <React.Fragment>
        {policyNumbers[0]}
        {isMultiplePolicies && (
          <Button
            variant="tertiary"
            size="xs"
            onClick={() => drawer.open({ value: policyNumbers })}
            aria-haspopup="dialog"
            style={{ marginLeft: '0.5rem', fontSize: '0.75rem' }}
          >
            See All
          </Button>
        )}
      </React.Fragment>
    );
  };

  const drawer = useOverlay('cellOverflow-drawer');
  const { data: drawerData } = drawer.getState();

  if (!accessToken || customMessagesFetching || planDocumentsFetching)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <LoadingSpinner
          size="$lg"
          isLoading={
            !accessToken || customMessagesFetching || planDocumentsFetching
          }
          ariaLoadingLabel="Loading dashboard..."
        />
      </div>
    );

  return (
    <React.Fragment>
      <Router.MetaTags title="Home" />
      <Tabs title="Author App" active={activeTab} onTabChange={setActiveTab}>
        <Tabs.Tab label="Plan Documents">
          <PlanDocumentsDataTable
            data={planDocumentsData?.planDocuments}
            fetching={planDocumentsFetching}
            error={planDocumentsError}
            drawerData={drawerData}
            dedupePolicyNumbers={dedupePolicyNumbers}
            generatePolicyNumberCell={generatePolicyNumberCell}
          />
        </Tabs.Tab>
        <Tabs.Tab label="Custom Messages">
          <CustomMessagesDataTable
            data={customMessagesData?.customMessages}
            fetching={customMessagesFetching}
            error={customMessagesError}
            drawerData={drawerData}
            dedupePolicyNumbers={dedupePolicyNumbers}
            generatePolicyNumberCell={generatePolicyNumberCell}
          />
        </Tabs.Tab>
      </Tabs>
    </React.Fragment>
  );
};
