import type { CustomMessage, Segment } from '@src/types';
import React, { useRef, useState } from 'react';
import { useDeleteCustomMessage } from '@src/hooks';
import { CustomMessageForm } from '@src/components';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Alert } from '@abyss/web/ui/Alert';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { styled } from '@abyss/web/tools/styled';
import { CombinedError } from 'urql';
import { RichText } from './common/RichText/RichText';

type CustomMessagesDataTableProps = {
  data: CustomMessage[] | undefined;
  fetching: boolean;
  error: CombinedError | undefined;
  drawerData: { value: string[] } | undefined;
  dedupePolicyNumbers: (policyNumbers: string[] | undefined) => string[];
  generatePolicyNumberCell: (value: Segment[]) => JSX.Element;
};

/**
 * @todo - Wire up the body field to the CustomMessageForm
 */
export const CustomMessagesDataTable = ({
  data,
  fetching,
  error,
  drawerData,
  dedupePolicyNumbers,
  generatePolicyNumberCell,
}: CustomMessagesDataTableProps) => {
  if (!data) return null;

  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState<boolean>(true);
  const [{ error: deleteCustomMessageError }, deleteCustomMessage] =
    useDeleteCustomMessage();

  const EDIT_MODAL_ID = 'modal-form-custom-message';
  const NEW_MODAL_ID = 'modal-form-custom-message-new';

  const editModal = useOverlay(EDIT_MODAL_ID);
  const newModal = useOverlay(NEW_MODAL_ID);

  const individualActions = [
    {
      label: 'Edit',
      icon: <IconMaterial icon="edit" />,
      onClick: (row: CustomMessage) => {
        editModal.open({ value: row });
      },
      buttonVariant: true,
    },
    {
      label: 'Delete',
      icon: <IconMaterial icon="delete" />,
      onClick: ({ row }) => {
        if (!row?.values?.id) {
          console.error('No ID found for row', row);
          return;
        }
        deleteCustomMessage({ input: { id: row.values.id } });

        if (deleteCustomMessageError) {
          console.error(
            'Error deleting plan document',
            deleteCustomMessageError
          );
        }
      },
    },
  ];

  const dataTableProps = useDataTable({
    data,
    showGlobalFilter: true,
    showFilterDataset: true,
    minColumnWidth: 120,
    individualActions,
    initialColumns: [
      {
        Header: 'ID',
        accessor: 'id',
        width: 210,
      },
      {
        Header: 'Title',
        accessor: 'properties.elements.title.value',
        width: 200,
      },
      {
        Header: 'Body',
        accessor: 'properties.elements.body.value',
        width: 200,
        Cell: ({ value }: { value: string }) => {
          if (!value) return 'N/A';
          return (
            <Container>
              <RichText noLineBreaks richTextTree={value} />
            </Container>
          );
        },
      },
      {
        Header: 'Policy Numbers',
        accessor: 'properties.metadata.value.segments',
        width: 100,
        id: 'policyNumbers',
        Cell: ({ value }: { value: Segment[] }) =>
          generatePolicyNumberCell(value),
      },
      {
        Header: 'Environments',
        accessor: 'environments',
        Cell: ({ value }: { value: string[] }) => {
          if (!value) return 'N/A';
          return value.join(', ');
        },
      },
      {
        Header: 'Activation Date',
        accessor: 'properties.metadata.value.activationDate',
        width: 100,
        Cell: ({ value }: { value: Date }) => {
          if (!value) return 'N/A';
          return new Date(value).toLocaleDateString();
        },
      },
      {
        Header: 'Deactivation Date',
        accessor: 'properties.metadata.value.deactivationDate',
        width: 100,
        Cell: ({ value }: { value: Date }) => {
          if (!value) return 'N/A';
          return new Date(value).toLocaleDateString();
        },
      },
    ],
  });

  const modalState = editModal.getState();

  const { id, environments, properties } =
    modalState?.data?.value?.row?.original || {};
  const { metadata, elements } = properties || {};
  const { body, title } = elements || {};
  const { value } = metadata || {};
  const { segments, activationDate, deactivationDate } = value || {};

  const policyNumbers = dedupePolicyNumbers(
    segments
      ?.filter((segment) => segment.policyNumber)
      ?.map((segment) => segment.policyNumber)
  );

  const initialValues = modalState?.data?.value?.row?.original
    ? {
        id,
        title: title?.value,
        body: body?.value,
        activationDate: activationDate
          ? new Date(activationDate).toLocaleDateString(undefined, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          : null,
        deactivationDate: deactivationDate
          ? new Date(deactivationDate).toLocaleDateString(undefined, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          : null,
        environments,
        policyNumbers,
      }
    : undefined;

  return (
    <Container>
      <LoadingSpinner
        ariaLoadingLabel="Downloading data"
        isLoading={fetching}
      />
      {error && (
        <Alert
          title="Error"
          variant="error"
          isVisible={isErrorAlertOpen}
          onClose={() => setIsErrorAlertOpen(false)}
        >
          {error.message}
        </Alert>
      )}
      <DataTable title="Custom Messages" tableState={dataTableProps} />
      <Button rounded size="$lg" onClick={() => newModal.open()}>
        <IconMaterial icon="add" color="$white" isScreenReadable />
      </Button>

      <CustomMessageForm
        id={EDIT_MODAL_ID}
        initialValues={initialValues}
        modal={editModal}
        isEdit
      />
      <CustomMessageForm id={NEW_MODAL_ID} modal={newModal} />

      <Drawer title="Cell Overflow Data" model="cellOverflow-drawer" size="$lg">
        <Drawer.Section>
          {drawerData && drawerData.value.join(', ')}
        </Drawer.Section>
      </Drawer>
    </Container>
  );
};

const Container = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '1rem',
});
