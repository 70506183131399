import React from 'react';
import { createTheme } from '@abyss/web/tools/theme';
import { createRouter } from '@abyss/web/tools/createRouter';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { GlobalStateProvider } from '@src/globalStateProvider';
import { URQLProvider } from './urqlProvider';

import { Routes } from './routes';

const theme = createTheme('uhg');
const router = createRouter(Routes);

export const browser = () => {
  return (
    <GlobalStateProvider>
      <URQLProvider>
        <AbyssProvider theme={theme} router={router} />
      </URQLProvider>
    </GlobalStateProvider>
  );
};
