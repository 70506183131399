import React, { useContext, useEffect } from 'react';
import { App } from '@src/components/App';
import { GlobalContext } from '@src/globalStateProvider';

export const Home = () => {
  const currentUrl = removeTrailingSlashAndQueryString(window.location.href);

  const { globalState, setGlobalState } = useContext(GlobalContext);
  const { accessToken } = globalState || {};

  useEffect(() => {
    if (accessToken) return;

    const code = new URLSearchParams(window.location.search).get('code');

    if (code) {
      const bodyParams = new URLSearchParams();
      bodyParams.set('grant_type', 'authorization_code');
      bodyParams.set('redirect_uri', `${currentUrl}`);
      bodyParams.set('code', code);
      bodyParams.set('client_id', process.env.NEXT_PUBLIC_AUTH_CLIENT_ID!);
      bodyParams.set(
        'code_verifier',
        process.env.NEXT_PUBLIC_AUTH_CODE_VERIFIER!
      );

      fetch(`${process.env.NEXT_PUBLIC_AUTH_TOKEN_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: bodyParams.toString(),
      })
        .then((response) => response.json())
        .catch((error) => {
          console.error('Failed to fetch:', error);
        })
        .then((data) => {
          if (!data?.access_token) {
            console.error('Fetch succeeded, but no access_token found:', data);
            return;
          }

          // remove code from url
          window.history.replaceState({}, document.title, currentUrl);
          setGlobalState((prev) => ({
            ...prev,
            accessToken: data?.access_token,
            refreshToken: data?.refresh_token,
          }));
        });
    } else {
      window.location.href = `${process.env.NEXT_PUBLIC_AUTH_SERVER_URL}?response_type=code&client_id=${process.env.NEXT_PUBLIC_AUTH_CLIENT_ID}&redirect_uri=${currentUrl}&acr_values=${process.env.NEXT_PUBLIC_AUTH_ACR_VALUES}&scope=openid%20profile%20address%20email%20phone&code_challenge=${process.env.NEXT_PUBLIC_AUTH_CODE_CHALLENGE}&code_challenge_method=${process.env.NEXT_PUBLIC_AUTH_CODE_CHALLENGE_METHOD}`;
    }
  }, []);

  return (
    <React.Fragment>
      <App accessToken={accessToken} />
    </React.Fragment>
  );
};

const removeTrailingSlashAndQueryString = (url: string): string => {
  return url.split('?')[0].replace(/\/$/, '');
};
