import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { Home } from './Home';

export const Routes = () => {
  return (
    <Router.Routes title="{{title}} | Abyss">
      <Router.Route path="/" element={<Home />} />
    </Router.Routes>
  );
};
