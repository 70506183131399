import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
} from 'react';

export type GlobalContextType = {
  globalState: {
    accessToken: string | null;
    refreshToken: string | null;
  };
  setGlobalState: Dispatch<
    SetStateAction<{ accessToken: null; refreshToken: null }>
  >;
};

export const GlobalContext = createContext<GlobalContextType>({
  globalState: {
    accessToken: null,
    refreshToken: null,
  },
  setGlobalState: () => {},
});

export const GlobalStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [globalState, setGlobalState] = useState({
    accessToken: null,
    refreshToken: null,
  });

  return (
    <GlobalContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};
