import { gql, useMutation } from 'urql';
import type { PlanDocument } from '@src/types';

export const useDeletePlanDocument = () => {
  const deletePlanDocument = gql`
    mutation DeletePlanDocument($input: DeletePlanDocument!) {
      deletePlanDocument(input: $input) {
        id
      }
    }
  `;

  return useMutation<PlanDocument, { input: Partial<PlanDocument> }>(
    deletePlanDocument
  );
};
