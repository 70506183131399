import React, { ReactNode, useContext } from 'react';
import {
  Client,
  Operation,
  Provider,
  cacheExchange,
  fetchExchange,
} from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { GraphQLError } from 'graphql';
import { GlobalContext } from './globalStateProvider';

export const URQLProvider = ({ children }: { children: ReactNode }) => {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const { accessToken, refreshToken } = globalState;

  let apiUrl: string;
  switch (window.location.origin) {
    case 'https://eni-author-app-stage.optum.com':
      apiUrl = 'https://eni-author-api-stage.optum.com/api/graphql';
      break;
    case 'https://eni-author-app.optum.com':
      apiUrl = 'https://eni-author-api.optum.com/api/graphql';
      break;
    default:
      apiUrl = 'http://localhost:4000/api/graphql';
      break;
  }

  const client = new Client({
    url: apiUrl,
    exchanges: [
      cacheExchange,
      authExchange(async (utils) => {
        return {
          addAuthToOperation(operation: Operation) {
            if (accessToken) {
              return utils.appendHeaders(operation, {
                Authorization: `Bearer ${accessToken}`,
                'Apollo-Require-Preflight': 'true',
              });
            }
            return operation;
          },
          didAuthError: (error: { graphQLErrors: GraphQLError[] }) => {
            return error.graphQLErrors.some(
              (e) => e.extensions?.code === 'UNAUTHENTICATED'
            );
          },
          refreshAuth: async () => {
            if (refreshToken) {
              const bodyParams = new URLSearchParams();
              bodyParams.set('grant_type', 'refresh_token');
              bodyParams.set('refresh_token', refreshToken);
              bodyParams.set(
                'client_id',
                process.env.NEXT_PUBLIC_AUTH_CLIENT_ID!
              );
              bodyParams.set(
                'code_verifier',
                process.env.NEXT_PUBLIC_AUTH_CODE_VERIFIER!
              );

              fetch(`${process.env.NEXT_PUBLIC_AUTH_TOKEN_URL}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: bodyParams.toString(),
              })
                .then((response) => response.json())
                .catch((error) => {
                  console.error('Error:', error);
                })
                .then((data) => {
                  setGlobalState((prev) => ({
                    ...prev,
                    accessToken: data?.access_token,
                    refreshToken: data?.refresh_token,
                  }));
                });
            } else {
              console.error('No refresh token available');
            }
          },
        };
      }),
      fetchExchange,
    ],
  });

  return <Provider value={client}>{children}</Provider>;
};
