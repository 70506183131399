import React, { useEffect, useState } from 'react';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Button } from '@abyss/web/ui/Button';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { Modal } from '@abyss/web/ui/Modal';
import { useCreatePlanDocument, useUpdatePlanDocument } from '@src/hooks';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Grid } from '@abyss/web/ui/Grid';
import { ToggleGroup } from '@abyss/web/ui/ToggleGroup';
import { Chip } from '@abyss/web/ui/Chip';
import { Flex } from '@abyss/web/ui/Flex';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { PlanDocument, Segment } from '@src/types';

type PlanDocFormValues = {
  id: string;
  title: string;
  href: string;
  activationDate: string;
  deactivationDate: string;
  environments: string[];
  policyNumbers?: string[];
  productIds?: string[];
  fundingArrangements: string[];
};

type PlanDocFormProps = {
  initialValues?: PlanDocFormValues;
  modal: ReturnType<typeof useOverlay>;
  id: string;
  isEdit?: boolean;
};

export const PlanDocForm = ({
  initialValues,
  modal,
  id,
  isEdit,
}: PlanDocFormProps) => {
  const [policyNumberArray, setPolicyNumberArray] = useState<string[]>([]);

  const form = useForm({
    initialValues: {
      title: '',
      href: '',
      activationDate: '',
      deactivationDate: '',
      environments: [],
      policyNumberInput: '',
      policyNumbers: [],
      productIds: [],
      fundingArrangements: [],
    },
  });

  const [{ error: createPlanDocError }, createPlanDoc] =
    useCreatePlanDocument();
  const [{ error: updatePlanDocError }, updatePlanDoc] =
    useUpdatePlanDocument();

  const onSubmit = (values: PlanDocFormValues) => {
    if (isEdit) {
      const { input } = generatePlanDocument(values, policyNumberArray);
      updatePlanDoc({ input });

      if (updatePlanDocError) {
        console.error(updatePlanDocError);
        return;
      }

      form.reset();
      setPolicyNumberArray([]);
      modal.close();
      return;
    }

    const { input } = generatePlanDocument(values, policyNumberArray);
    createPlanDoc({ input });

    if (createPlanDocError) {
      console.error(createPlanDocError);
      return;
    }

    form.reset();
    setPolicyNumberArray([]);
    modal.close();
  };

  useEffect(() => {
    if (isEdit) {
      form.reset(initialValues);
      setPolicyNumberArray(initialValues?.policyNumbers || []);
      return;
    }

    form.reset();
  }, [initialValues]);

  return (
    <Modal
      title={isEdit ? 'Edit Plan Document' : 'New Plan Document'}
      model={id}
    >
      <Modal.Section>
        <FormProvider state={form} onSubmit={onSubmit}>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Title"
                placeholder="Title"
                model="title"
                validators={{ required: true }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <ToggleGroup
                label="Environments"
                model="environments"
                display="row"
                validators={{ required: true }}
              >
                <ToggleGroup.Toggle label="PROD" value="PROD" />
                <ToggleGroup.Toggle label="UAT" value="UAT" />
                <ToggleGroup.Toggle label="INT" value="INT" />
              </ToggleGroup>
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label="Document URL"
                placeholder="Document URL"
                model="href"
                validators={{ required: true }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <DateInput label="Activation Date" model="activationDate" />
            </Grid.Col>
            <Grid.Col span={6}>
              <DateInput label="Deactivation Date" model="deactivationDate" />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label="Policy Numbers"
                placeholder="Type and press enter to add"
                model="policyNumberInput"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();

                    setPolicyNumberArray([
                      ...policyNumberArray,
                      ...e.target.value.split(/[\s,]+/),
                    ]);

                    e.target.value = '';
                  }
                }}
              />
              <Chip.Group>
                <Flex style={{ marginTop: '0.5rem', gap: '0.25rem' }}>
                  {policyNumberArray?.map((policyNumber) => (
                    <Chip
                      key={policyNumber}
                      text={policyNumber}
                      onClose={() => {
                        setPolicyNumberArray((prev) =>
                          prev.filter((item) => item !== policyNumber)
                        );
                      }}
                    />
                  ))}
                </Flex>
              </Chip.Group>
            </Grid.Col>
            <Grid.Col span={12}>
              <SelectInputMulti
                label="Product IDs"
                model="productIds"
                options={productIds}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <SelectInputMulti
                label="Funding Arrangements"
                model="fundingArrangements"
                options={[
                  {
                    section: 'Funding Arrangements',
                    items: [
                      {
                        label: 'Admin Services Only',
                        value: 'A',
                      },
                      {
                        label: 'Fully Insured',
                        value: 'F',
                      },
                    ],
                  },
                  {
                    section: 'Situs States',
                    items: situsStates,
                  },
                ]}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Button type="submit">Submit</Button>
            </Grid.Col>
            {createPlanDocError && <Grid.Col>{createPlanDocError}</Grid.Col>}
          </Grid>
        </FormProvider>
      </Modal.Section>
    </Modal>
  );
};

const generatePlanDocument = (
  {
    id,
    title,
    productIds,
    fundingArrangements,
    href,
    activationDate,
    deactivationDate,
    environments,
  }: PlanDocFormValues,
  policyNumberArray: string[]
): { input: PlanDocument } => {
  const policyNumberSegments = policyNumberArray.map((policyNumber) => ({
    policyNumber,
    situsStates: [],
    segmentId: '',
    productIds: [],
    fundingArrangements: [],
    pvrCodes: [],
  }));

  const productIdCodes = productIds?.filter((item: string) => item.length > 1);

  const fundingArrangementCodes = fundingArrangements?.filter(
    (item: string) => item.length === 1
  );

  const situsStates = fundingArrangements?.filter(
    (item: string) => item.length > 1
  );

  const dimensionsSegment = {
    productIds: productIdCodes,
    fundingArrangements: fundingArrangementCodes,
    situsStates,
  };

  const segments = [...policyNumberSegments, dimensionsSegment] as Segment[];

  return {
    input: {
      id,
      properties: {
        metadata: {
          value: {
            segments,
            activationDate: activationDate
              ? new Date(activationDate).toISOString()
              : null,
            deactivationDate: deactivationDate
              ? new Date(deactivationDate).toISOString()
              : null,
          },
        },
        elements: {
          title: {
            value: title,
          },
          href: {
            value: href,
          },
          mimeType: {
            value: 'application/pdf',
          },
        },
      },
      environments,
    },
  };
};

const productIds = [
  { label: 'USP Fully Insured', value: 'UH' },
  { label: 'Oxford', value: 'OX' },
  { label: 'Level Funded', value: 'LF' },
  { label: 'River Valley', value: 'OL' },
  { label: 'Tufts', value: 'RV' },
  { label: 'Neighborhood Health Plan', value: 'NP' },
  { label: 'Optimum Care', value: 'OC' },
];

const situsStates = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
  {
    label: 'District of Columbia',
    value: 'DC',
  },
];
