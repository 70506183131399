import React, { useEffect, useRef, useState } from 'react';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Button } from '@abyss/web/ui/Button';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { Modal } from '@abyss/web/ui/Modal';
import { useCreateCustomMessage, useUpdateCustomMessage } from '@src/hooks';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Grid } from '@abyss/web/ui/Grid';
import { ToggleGroup } from '@abyss/web/ui/ToggleGroup';
import { Chip } from '@abyss/web/ui/Chip';
import { Flex } from '@abyss/web/ui/Flex';
import { CustomMessage } from '@src/types';
import RichTextEditor from './common/RichText/RichTextEditor';

type CustomMessageFormValues = {
  id: string;
  title: string;
  body: Object[];
  activationDate: string;
  deactivationDate: string;
  environments: string[];
  policyNumbers?: string[];
};

type CustomMessageFormProps = {
  initialValues?: CustomMessageFormValues;
  modal: ReturnType<typeof useOverlay>;
  id: string;
  isEdit?: boolean;
};

export const CustomMessageForm = ({
  initialValues,
  modal,
  id,
  isEdit,
}: CustomMessageFormProps) => {
  const [policyNumberArray, setPolicyNumberArray] = useState<string[]>([]);
  const form = useForm();

  const [{ error: createCustomMessageError }, createCustomMessage] =
    useCreateCustomMessage();
  const [{ error: updateCustomMessageError }, updateCustomMessage] =
    useUpdateCustomMessage();

  const onSubmit = (values: CustomMessageFormValues) => {
    if (isEdit) {
      const { input } = generateCustomMessage(values, policyNumberArray);
      updateCustomMessage({ input });

      if (updateCustomMessageError) {
        console.error(updateCustomMessageError);
        return;
      }

      form.reset();
      setPolicyNumberArray([]);
      modal.close();
      return;
    }

    const { input } = generateCustomMessage(values, policyNumberArray);
    createCustomMessage({ input });

    if (createCustomMessageError) {
      console.error(createCustomMessageError);
      return;
    }

    form.reset();
    setPolicyNumberArray([]);
    modal.close();
  };

  useEffect(() => {
    if (isEdit) {
      form.reset(initialValues);
      setPolicyNumberArray(initialValues?.policyNumbers || []);
    }
  }, [initialValues, isEdit]);

  const { body } = initialValues || {};

  const defaultEditorState = [{ type: 'paragraph', children: [{ text: '' }] }];

  return (
    <Modal
      title={isEdit ? 'Edit Custom Message' : 'New Custom Message'}
      model={id}
    >
      <Modal.Section>
        <FormProvider state={form} onSubmit={onSubmit}>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Title"
                placeholder="Title"
                model="title"
                validators={{ required: true }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <ToggleGroup
                label="Environments"
                model="environments"
                display="row"
                validators={{ required: true }}
              >
                <ToggleGroup.Toggle label="PROD" value="PROD" />
                <ToggleGroup.Toggle label="UAT" value="UAT" />
                <ToggleGroup.Toggle label="INT" value="INT" />
              </ToggleGroup>
            </Grid.Col>
            <Grid.Col span={12}>
              <RichTextEditor
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                }}
                id="body"
                startingValue={body || defaultEditorState}
                onValueChanged={(e) => {
                  form.setValue('body', e);
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <DateInput label="Activation Date" model="activationDate" />
            </Grid.Col>
            <Grid.Col span={6}>
              <DateInput label="Deactivation Date" model="deactivationDate" />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label="Policy Numbers"
                placeholder="Type and press enter to add"
                model="policyNumberInput"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();

                    setPolicyNumberArray([
                      ...policyNumberArray,
                      ...e.target.value.split(/[\s,]+/),
                    ]);

                    e.target.value = '';
                  }
                }}
              />
              <Chip.Group>
                <Flex style={{ marginTop: '0.5rem', gap: '0.25rem' }}>
                  {policyNumberArray?.map((policyNumber) => (
                    <Chip
                      key={policyNumber}
                      text={policyNumber}
                      onClose={() => {
                        setPolicyNumberArray((prev) =>
                          prev.filter((item) => item !== policyNumber)
                        );
                      }}
                    />
                  ))}
                </Flex>
              </Chip.Group>
            </Grid.Col>
            <Grid.Col span={12}>
              <Button type="submit">Submit</Button>
            </Grid.Col>
            {createCustomMessageError && (
              <Grid.Col>{createCustomMessageError}</Grid.Col>
            )}
          </Grid>
        </FormProvider>
      </Modal.Section>
    </Modal>
  );
};

const generateCustomMessage = (
  {
    id,
    title,
    body,
    activationDate,
    deactivationDate,
    environments,
  }: CustomMessageFormValues,
  policyNumberArray: string[]
): { input: CustomMessage } => {
  const segments = policyNumberArray.map((policyNumber) => ({
    policyNumber,
    pvrCodes: [],
  }));

  return {
    input: {
      id,
      properties: {
        metadata: {
          value: {
            segments,
            activationDate: activationDate
              ? new Date(activationDate).toISOString()
              : null,
            deactivationDate: deactivationDate
              ? new Date(deactivationDate).toISOString()
              : null,
          },
        },
        elements: {
          title: {
            value: title,
          },
          body: {
            value: body,
          },
        },
      },
      environments,
    },
  };
};
