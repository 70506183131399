import { gql, useMutation } from 'urql';
import type { CustomMessage } from '@src/types';

export const useDeleteCustomMessage = () => {
  const deleteCustomMessage = gql`
    mutation DeleteCustomMessage($input: DeleteCustomMessageByIdInput!) {
      deleteCustomMessageById(input: $input) {
        id
      }
    }
  `;

  return useMutation<CustomMessage, { input: Partial<CustomMessage> }>(
    deleteCustomMessage
  );
};
